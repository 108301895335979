import React, {useState, useEffect} from 'react';
import './App.css';
import {PanoramaScene} from "./PanoramaScene";
import {ApiClient, PanoramaTour} from "./api/apiClient";
import {BrowserUI} from "./components/ui/browser/BrowserUI";
import {Backdrop, CircularProgress, Stack, Typography} from "@mui/material";
import {Vector3} from "three";
import {launchPanoramaChangeAnimations, resetCamera, stopHouseTour} from "./components/common";

export interface AppProps {
    contentUrl: string;
}

function App(props: AppProps) {

    const [panoramaTour, setPanoramaTour] = useState<PanoramaTour>();
    const [isVrMode, setIsVrMode] = useState(false);
    const [userSelectedPanorama, setUserSelectedPanorama] = useState<undefined | string>(undefined);
    const [loading, setLoading] = useState(true);

    const urlParams = new URLSearchParams(window.location.search);
    let defaultPanorama = urlParams.get('defaultsky');

    const apiClient = new ApiClient(props.contentUrl);

    useEffect(() => {
        apiClient.getPanoramaTour().then((result) => {
            if (result) {
                setPanoramaTour(result);
            }
        });
    }, []);

    const getPanoramaData = (panoramaName: string) => {
        const panoramaData = panoramaTour?.panoramas.find(p => p.name === panoramaName);

        if (!panoramaData) {
            throw new Error("Panorama not found: " + panoramaName);
        }
        return panoramaData;
    }

    const changePanorama = (newPanorama: string, zoomTowards?: Vector3, continueTour?: boolean) => {
        console.log("Changing to: " + newPanorama);

        const newPanoramaData = getPanoramaData(newPanorama);

        // Launch animations for changing panorama. If zoomTowards is set, zoom towards that direction. Otherwise, use default look at direction.
        launchPanoramaChangeAnimations(zoomTowards, zoomTowards ? undefined : newPanoramaData.defaultLookAtDirection);

        // Stop the tour if change was caused by user interaction.
        if (!continueTour) {
            stopHouseTour();
        }

        // Change the panorama on small delay, so animations can finish.
        setTimeout(() => setUserSelectedPanorama(newPanorama), 700);
    };

    const startPanorama = defaultPanorama && panoramaTour?.panoramas.some(p => p.name === defaultPanorama) ?
        defaultPanorama : panoramaTour?.panoramas[0]?.name;
    const currentPanorama = userSelectedPanorama ?? startPanorama;

    useEffect(() => {
        // When the tour is initially loaded, point the camera at the default look at direction.
        if (!loading && startPanorama && panoramaTour) {
            const startPanoramaData = getPanoramaData(startPanorama);
            resetCamera(startPanoramaData.defaultLookAtDirection);
        }
    }, [startPanorama, panoramaTour, loading]);

    return <div style={{position: "absolute", height: "100%", width: "100%"}} id="root">
      { panoramaTour && currentPanorama && startPanorama && <>
          {!isVrMode && <BrowserUI floorPlans={panoramaTour.floorPlans} rooms={panoramaTour.rooms} changePanorama={changePanorama} currentPanorama={currentPanorama} />}

          <PanoramaScene tour={panoramaTour} currentPanorama={currentPanorama} startPanorama={startPanorama} changePanorama={changePanorama}
                         sceneLoaded={() => setLoading(false)}
                         setIsVrMode={setIsVrMode} isVrMode={isVrMode}
                    />
      </>
      }
      <Backdrop
          open={loading}
          sx={{ bgcolor: 'rgba(255, 255, 255, 1)', color: '#fff', zIndex: 999999}}
      >
          <Stack direction={"row"} spacing={5} alignItems={"center"}>
            <CircularProgress size={100} /> <Typography variant={"h4"} color={"black"}>Starting tour...</Typography>
          </Stack>
      </Backdrop>

    </div>
}

export default App;
