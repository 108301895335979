import {Entity, THREE} from "aframe";
import {Vector3} from "three";

export function launchPanoramaChangeAnimations(zoomTowards?: Vector3, rotateDirection?: Vector3) {
    const sky = document.querySelector('a-sky');
    const cameraRig = document.querySelector('#cameraRig');
    const scene = AFRAME.scenes[0];

    var spots = Array.prototype.slice.call(scene.querySelectorAll('.panoramaHotspot'));

    // Hide hotspots when animation starts
    spots.forEach(function (spot) {
        spot.object3D.visible = false;
    });

    // Emit the fade event.
    sky.emit("fade", null, false);

    // If panorama hotspot was clicked, zoom towards it.
    if (zoomTowards) {
        cameraRig.emit("zoomTowards", {zoomTo: zoomTowards}, false);
    }
    // If menu was used, reset the camera so that user looks at the default direction of the panorama.
    if (rotateDirection) {
        resetCamera(rotateDirection);
    }
}

// Reset camera to point at the default direction. This is done by rotating the camera rig, since camera shouldn't be rotated.
// The method calculates the necessary rotation for the camera rig to point at the target direction, taking into account the current camera rotation.
// The camera rig is only rotated horizontally. Adding vertical rotation causes the camera's axes to rotate, which is not desired.
export function resetCamera(targetDirection: Vector3) {
    // Get camera rig and camera
    const camera = document.querySelector('#camera');
    const cameraRig = document.querySelector('#cameraRig');

    // The rotation animation of the minimap cone is ugly. Hide the cone and show it again after the panorama has changed.
    const cone = document.querySelector('#cone');
    if (cone) {
        cone.style.visibility = "hidden";
        setTimeout(() => {
            cone.style.visibility = "visible";
        }, 700);
    }

    // Step 1: Get the camera's current world position
    const cameraWorldPosition = new THREE.Vector3();
    camera.object3D.getWorldPosition(cameraWorldPosition);

    // Step 2: Calculate the horizontal direction to the target
    targetDirection.y = cameraWorldPosition.y; // Ignore vertical differences
    const horizontalDirection = targetDirection.sub(cameraWorldPosition).normalize();

    // Step 3: Calculate the target yaw (rotation around Y-axis) in world space
    const targetYaw = Math.atan2(-horizontalDirection.x, -horizontalDirection.z);

    // Step 4: Get the camera's current world yaw
    const cameraWorldQuaternion = new THREE.Quaternion();
    camera.object3D.getWorldQuaternion(cameraWorldQuaternion);

    const cameraWorldEuler = new THREE.Euler();
    cameraWorldEuler.setFromQuaternion(cameraWorldQuaternion, 'YXZ'); // Extract yaw
    const cameraYaw = cameraWorldEuler.y; // Y-axis rotation of the camera in world space

    // Step 5: Get the rig's current world yaw
    const rigWorldQuaternion = new THREE.Quaternion();
    cameraRig.object3D.getWorldQuaternion(rigWorldQuaternion);

    const rigWorldEuler = new THREE.Euler();
    rigWorldEuler.setFromQuaternion(rigWorldQuaternion, 'YXZ'); // Extract yaw
    const rigYaw = rigWorldEuler.y;

    // Step 6: Calculate the necessary adjustment for the rig
    const rigAdjustmentYaw = targetYaw - cameraYaw;

    // Step 7: Apply the adjustment to the rig's current yaw
    cameraRig.object3D.rotation.set(0, rigYaw + rigAdjustmentYaw, 0); // Set only Y-axis rotation
}

export function isVRMode() {
    const scene = document.querySelector("a-scene");

    return scene && scene.is('vr-mode');
}


// Move entity to the front of the camera.
export function bringToFront(entity: Entity) {

    const position = entity.object3D.position // the reference to our position
    const camera = entity.sceneEl!.camera // the reference to the THREE.Camera
    const front = new THREE.Vector3(0, 0, -1);
    camera.localToWorld(front);
    position.copy(front) // use it as the position

    entity.object3D.position.set(position.x, position.y, position.z);
}

export function startHouseTour() {
    document.querySelector('#cameraRig').emit("startTour", null, false);
}

export function exitFullscreen() {
    // Verify that canvas is fullscreen.
    const canvas = document.querySelector(".a-canvas");
    if (document.fullscreenElement === canvas) {
        // Exit fullscreen.
        document.exitFullscreen();
    }
}

export function stopHouseTour() {
    const camera = document.querySelector('#cameraRig');
    camera.emit("pauseTour", null, false);
}

export function calculateLinearInterpolation(input: number, minInput: number, maxInput: number, minOutput: number, maxOutput: number, reverse: boolean = false) {
    let output: number;

    if (minInput === maxInput || minOutput === maxOutput) {
        throw new Error("Same max and min values... seriously?");
    }

    if (input < minInput) {
        output = maxOutput;
    }
    else if (input > maxInput) {
        output = minOutput;
    }
    else {
        if (reverse) {
            // Normalize the input to a value between 0 and 1
            const normalizedInput = (input - minInput) / (maxInput - minInput);

            // Perform reversed linear interpolation within the output range
            output = maxOutput - normalizedInput * (maxOutput - minOutput);
        }
        else {
            // Perform linear interpolation within the output range
            output = minOutput + (input - minInput) / (maxInput - minInput) * (maxOutput - minOutput);
        }
    }

    return output;
}