import {Floorplan, FloorplanHotspot} from "../../../api/apiClient";
import { ReactComponent as Cone} from "../../../images/ui/cone.svg"
import "../../../animations/fadeIn.css"
import {alpha, IconButton, styled, useMediaQuery, useTheme} from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import {Vector3} from "three";

export interface BrowserFloorMapProps {
    changePanorama: (panorama: string, zoomTowards?: Vector3, continueTour?: boolean) => void;
    floorPlan: Floorplan;
    currentPanorama: string;
    onFloorPlanImageLoaded: () => void;
    minimapHeightVH: number | undefined;
    minimapWidthVW: number | undefined;
}

// This custom circle has a border around it.
function CustomCircleIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
            >
                <circle cx="12" cy="12" r="10" fill="currentColor"/>
                <circle cx="12" cy="12" r="8" fill="none" stroke="currentColor" strokeWidth="2"/>
            </svg>
        </SvgIcon>
    );
}

export function BrowserFloorMap(props: BrowserFloorMapProps) {
    const {changePanorama, floorPlan, currentPanorama, onFloorPlanImageLoaded, minimapHeightVH, minimapWidthVW} = props;

    const theme = useTheme();

    const currentHotSpot = floorPlan.hotspots.find(hp => hp.targetPanorama === currentPanorama);
    const imageAspectRatio = floorPlan.imageWidth/floorPlan.imageHeight;
    const isVeryLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));

    // Get viewport dimensions in pixels
    let viewportHeight = window.innerHeight;
    let viewportWidth = window.innerWidth;

    // Calculate image height in pixels
    let imageHeightPx = minimapHeightVH ? (minimapHeightVH! / 100) * viewportHeight : undefined;

    // Calculate image width in pixels
    let imageWidthPx = minimapWidthVW ? (minimapWidthVW / 100 * viewportWidth) : (imageHeightPx! * imageAspectRatio);

    // Adjust hotspot size based on how big the image is when rendered to the screen. In desktop size, make the hotspot bit larger.
    let hotSpotIconWidthPercentage = (isVeryLargeScreen ? 4 : 3) / (imageWidthPx / 1000); // / (window.innerWidth/2000); //baseSizeVW * imageScreenRatio; // Scale size based on image-to-screen ratio

    // Custom icon button with sizes in percentage and custom colors.
    const HotSpotIconButton = styled(IconButton)(({ theme }) => ({
        width: hotSpotIconWidthPercentage + "%",
        height: "auto",
        aspectRatio: "1/1",
        padding: 5,
        color: theme.palette.primary.main,
        '& .MuiSvgIcon-root': {
            width: '100%',
            height: '100%',
            fontSize: 'inherit',
            stroke: theme.palette.primary.contrastText
        },
    }));

    // Calculate height for the hotspot, percentages are different even when the icon is circle, because the image won't be a square.
    const hotSpotIconHeightPercentage = (hotSpotIconWidthPercentage / 100 * floorPlan.imageWidth) / floorPlan.imageHeight * 100;

    const hotSpotImageSizePercentage = {width: hotSpotIconWidthPercentage, height: hotSpotIconHeightPercentage};

    // Position of the hotspot currently displayed.
    const currentHotSpotPosition = currentHotSpot ? calculateHotSpotPosition(currentHotSpot, floorPlan, hotSpotImageSizePercentage) : undefined;

    return <>
            {/*The floorplan image*/}
             <img
                 src={floorPlan.image}
                 alt="Placeholder"
                 style={{
                     maxHeight: '100%', // Ensure the image fits within its container
                     maxWidth: '100%', // Ensure the image maintains aspect ratio
                     objectFit: 'contain',
                     display: "block"
                 }}
                 onLoad={onFloorPlanImageLoaded}
             />

            {/*Hotspots*/}
            {floorPlan.hotspots.map((hotSpot) => {

                const position = calculateHotSpotPosition(hotSpot, floorPlan, hotSpotImageSizePercentage);

                return <HotSpotIconButton key={"browser-minimap-button-" + hotSpot.targetPanorama}
                                   sx={{
                                       position: "absolute",
                                       zIndex: 2,
                                       top: position.top + "%",
                                       left: position.left + "%",
                                       backgroundColor: hotSpot.targetPanorama === currentHotSpot?.targetPanorama ? alpha(theme.palette.background.default, 0.4) : "",
                                       ":hover": {
                                           backgroundColor: alpha(theme.palette.background.default, 0.4),
                                       }
                                   }}

                                   onClick={() => {
                                       changePanorama(hotSpot.targetPanorama);
                                   }}>
                    <CustomCircleIcon fontSize={"inherit"}/>
                </HotSpotIconButton>
            })}

            {/*The view cone*/}
            {currentHotSpotPosition && <div id={"cone"} className={"fade-in"}
                                            style={{
                                                top: (currentHotSpotPosition.top + (hotSpotImageSizePercentage.height / 2)) +"%",
                                                left: (currentHotSpotPosition.left + (hotSpotImageSizePercentage.width / 2) - 10) + "%",
                                                width: "20%",
                                                margin: 0,
                                                padding: 0,
                                                position: "absolute",
                                                zIndex: 1,
                                                transition: 'transform 0.1s linear',
                                                transformOrigin: "top center"
                                            }}>
                <Cone style={{fill: theme.palette.background.default, opacity: 0.7, margin: 0, padding: 0}}/>
            </div>}
     </>
}

// Transform the position of hotspot from pixels in the image to percentage of the containing div.
function calculateHotSpotPosition(hotSpot: FloorplanHotspot, floorPlan: Floorplan, hotSpotSize: {width: number, height: number}) {

    const top = (hotSpot.y / floorPlan.imageHeight * 100) - (hotSpotSize.height / 2); // Reduce to cover the button size
    const left = (hotSpot.x / floorPlan.imageWidth * 100) - (hotSpotSize.width / 2);

    return {top: top, left: left};
}