import {LocationChangeSpot, Panorama} from "../../api/apiClient";
import React from "react";
import {FloorHotSpot} from "./FloorHotSpot";
import {IconHotSpot} from "./IconHotSpot";
import {Vector3} from "three";

export function PanoramaHotSpot(props: {spot: LocationChangeSpot, panorama: Panorama, changePanorama: (value: string, zoomTowards?: Vector3, continueTour?: boolean) => void, isVrMode: boolean}) {
    const { isVrMode, spot, changePanorama, panorama } = props;

    const isSpot = spot.type === "Spot";

    const clickEvent = () => {
        changePanorama(spot.targetPanorama, spot.position);
    }

    const id = panorama.name + "-" + spot.targetPanorama;

    return <>
        {isSpot && <FloorHotSpot id={id} position={spot.position} onClick={clickEvent} isVrMode={isVrMode}/>}
        {!isSpot && <IconHotSpot id={id} spot={spot} onClick={clickEvent} isVrMode={isVrMode} />}
    </>
}