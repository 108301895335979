// @ts-ignore
import {Entity} from 'aframe-react';
import React, {useEffect, useRef, useState} from "react";
import {PanoramaTour} from "../../api/apiClient";
import anime from "animejs";
import {Vector3} from "three";

export interface CameraRigProps {
    children?: React.ReactNode;
    vrController: React.ReactElement;
    tour: PanoramaTour;
    changePanorama: (newPanorama: string, zoomTowards?: Vector3, continueTour?: boolean) => void;
    currentPanorama: string;
    isVrMode: boolean;
    vrMinimapVisible: boolean;
}

enum TourStatus{
    NotStarted,
    Running,
    Stopped
}

export function CameraRig(props: CameraRigProps) {
    const {children, tour, changePanorama, currentPanorama,
        vrController, isVrMode, vrMinimapVisible} = props;

    const cameraRigRef = useRef<Entity>(null);

    const [tourAnimation, setTourAnimation] = useState<anime.AnimeInstance | undefined>(undefined);
    const [currentTourStatus, setCurrentTourStatus] = useState<TourStatus>(TourStatus.NotStarted);

    useEffect(() => {
        // When panorama has changed, restart the tour animation if tour should be running.
        if (currentTourStatus === TourStatus.Running) {
            const rig = cameraRigRef.current;

            setTourAnimation(anime({
                targets: rig.el.object3D.rotation,
                y: [rig.el.object3D.rotation.y, rig.el.object3D.rotation.y + Math.PI * 2], // Rotate between start rotation and full circle
                duration: 50000,
                easing: 'easeInOutQuad',
                loop: false,
                autoplay: true,
                complete: handleTourLoopComplete
            }));
        }
    }, [currentPanorama, currentTourStatus]);

    const handleTourLoopComplete = () => {

        // After every loop we need to change the panorama image to another.

        // Find out what position does the image have in the image list.
        const currentPanoramaIndex = tour.panoramas.findIndex((p) => p.name === currentPanorama);
        let nextPanoramaIndex: number;

        // If image is the last image, start from the beginning.
        if (currentPanoramaIndex === tour.panoramas.length - 1) {
            nextPanoramaIndex = -1;
        }
        else {
            nextPanoramaIndex = currentPanoramaIndex + 1;
        }

        const nextPanorama = tour.panoramas[nextPanoramaIndex];

        changePanorama(nextPanorama.name, undefined, true);
    };


    // The a-camera has lot of functionality built in, it's better to put our functionalities to a parent "camera rig" entity.
    return <Entity id="cameraRig" camerazoom
                    position="0 0 0"
                    ref={cameraRigRef}
                    events={{
                        startTour: function (event: CustomEvent) {
                            // Start the tour after small delay.
                            setTimeout(() => {
                                    setCurrentTourStatus(curr => curr === TourStatus.NotStarted ? TourStatus.Running : TourStatus.Stopped);
                            }, 10000);
                        },
                        pauseTour: function () {
                            if (tourAnimation && currentTourStatus === TourStatus.Running) {
                                // Pause the animation
                                tourAnimation.pause();
                            }

                            setCurrentTourStatus(TourStatus.Stopped);
                        },
                        zoomTowards: function (event: CustomEvent) {

                            let zoomerPosition = event.detail.zoomTo;
                            let animX = zoomerPosition.x > 0 ? zoomerPosition.x + 20 : zoomerPosition.x - 20;
                            let animZ = zoomerPosition.z > 0 ? zoomerPosition.z + 20 : zoomerPosition.z - 20;

                            const animPosition = animX + " 0 " + animZ;
                            const rig = cameraRigRef.current.el;

                            anime({
                                targets: rig,
                                position: ["0 0 0", animPosition],
                                duration: 700,
                                easing: 'easeInSine',
                                complete: function () {
                                    anime({
                                        targets: rig,
                                        position: [animPosition, "0 0 0"],
                                        duration: 1,
                                        easing: 'easeInSine'
                                    });
                                }
                            });
                        }
                    }}
    >
        {/* Camera entity specifies the way we view the scene. Children of the camera entity will move with the camera. */}
        <Entity primitive="a-camera" id="camera"
                rotationreader={{isVrMode: isVrMode, vrMinimapVisible: vrMinimapVisible,
                    floorPlanTilt: tour.floorPlans.find(fp => fp.hotspots.some((hp) => hp.targetPanorama === currentPanorama))?.imageTilt ?? 0}}
                fov="70" wasd-controls="enabled: false;"
                look-controls="enabled: true; reverseMouseDrag: true;" position="0 0 0">
            {children}
        </Entity>
        {vrController}
        <Entity laser-controls="hand: right" raycaster="objects: .raycastable; far: 100; lineColor: blue;" />
        <Entity laser-controls="hand: left" raycaster="objects: .raycastable; far: 100; lineColor: blue;" />
    </Entity>
}