import {THREE} from "aframe";

AFRAME.registerComponent('rotationreader', {
    schema: {
        isVrMode:  {type: 'boolean'},
        vrMinimapVisible: {type: 'boolean'},
        floorPlanTilt: {type: 'number'}
    },
    tick: function () {
        const isVrMode = this.data.isVrMode;
        const floorPlanTilt = this.data.floorPlanTilt;

        // The angle the cone should point to the right if there is no camera rotation and no floor plan image tilt.
        //
        // The zero direction should be changed if the floor plan has been tilted.
        // Positive rotation rotates the cone counter-clockwise. Negative rotation rotates the image clockwise.
        let viewConeZeroAngle = 90 + floorPlanTilt;

        // In browser, zero value would mean pointing straight down. In VR UI the cone is produced differently, there -150 is down.
        if (isVrMode) {
            viewConeZeroAngle -= 150;
        }

        // @ts-ignore Get camera rotation
        const cameraViewPoint = THREE.MathUtils.radToDeg(this.el.parentEl.object3D.rotation.y + this.el.object3D.rotation.y);

        const newRotation = viewConeZeroAngle + cameraViewPoint;

        // In VR we can only set the new direction if the minimap is visible.
        if (isVrMode && this.data.vrMinimapVisible) {
            // @ts-ignore
            const minimapCone = this.el.parentEl.querySelector("#minimapViewCone");
            if (minimapCone) {
                // @ts-ignore
                AFRAME.utils.entity.setComponentProperty(minimapCone, 'geometry.thetaStart', newRotation);
            }
        }
        else {
            const browserMinimapCone = document.getElementById("cone");

            if (browserMinimapCone) {

                browserMinimapCone.style.transform = `rotate(${-newRotation}deg)`;
            }
        }
    }
});
